body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  color: white;
  cursor: url('/public/cursor.svg') 16 0, default;
}
button {
  cursor: url('/public/cursorp.svg') 16 0, pointer;
}
.card {
  cursor: url('/public/cursorp.svg') 16 0, pointer;
}
